import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { Link } from 'gatsby';

const Menu = ({ open }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [open]);

  return (
    <StyledMenu open={open}>
      <Link activeClassName="mobileActiveLink" to="/tilaratkaisu">
        Tilaratkaisu
      </Link>
      <Link activeClassName="mobileActiveLink" to="/sijainti-ja-palvelut">
        Sijainti
      </Link>
      <Link activeClassName="mobileActiveLink" to="/talo">
        Talo
      </Link>
      <Link activeClassName="mobileActiveLink" to="/tyyli-ja-sisustus">
        Tyyli & Sisustus
      </Link>
      <Link activeClassName="mobileActiveLink" to="/blogi">
        Blogi
      </Link>
      <Link activeClassName="mobileActiveLink" to="/vuokralainen">
        Vuokralainen
      </Link>
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: bool.isRequired,
};
export default Menu;
