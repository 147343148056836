import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Heading, Flex, Box, Text, Image } from 'rebass';
import { Main, Section, Container, Btn } from '../components/Elements';
import menuSvg from '../images/menu_btn.svg';
import { Burger, Menu } from '../components';

const logoQuery = graphql`
  {
    datoCmsLogot {
      logoGold {
        url
        alt
      }
      logoValkoinen {
        url
        alt
      }
    }
  }
`;

const Header = ({ logo }) => {
  const data = useStaticQuery(logoQuery);
  const { logoGold, logoValkoinen } = data.datoCmsLogot;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 200) {
        if (prevScrollpos > currentScrollPos) {
          document.getElementById('navbar').style.top = '0';
        } else {
          document.getElementById('navbar').style.top = '-60px';
        }
      } else {
        document.getElementById('navbar').style.top = '0';
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  let mobileLogo = logoGold;

  if (logo == 'white') {
    mobileLogo = logoGold;
  }

  return (
    <Flex
      id="navbar"
      as="header"
      bg={['white', 'white', 'white', 'white']}
      color={'gold'}
      height={60}
      sx={{
        position: 'fixed',
        width: '100%',
        zIndex: 1000,
        fontWeight: 600,
        letterSpacing: '1.5px',
        transition: 'top 0.3s',
      }}
    >
      <Flex mx="auto" width="100%">
        <Flex
          width={260}
          justifyContent={['flex-start', 'flex-start']}
          alignItems="center"
          pl={1}
        >
          <Link to="/">
            <Image
              display={['none', 'none', 'block']}
              src={logoGold.url}
              alt={logoGold.alt}
              width={104}
              mt={'-4px'}
            />
            <Image
              display={['block', 'block', 'none']}
              src={mobileLogo.url}
              alt={mobileLogo.alt}
              width={104}
              mt={'-4px'}
            />
          </Link>
        </Flex>

        <Flex
          className="desktopMenu"
          width={1}
          justifyContent="flex-end"
          pr={4}
        >
          <Flex
            className="mainmenu"
            as="nav"
            justifyContent="center"
            maxWidth={690}
            alignItems="center"
          >
            <Link activeClassName="activeLink" to="/tilaratkaisu">
              <span>Tilat</span>
            </Link>
            <Link activeClassName="activeLink" to="/sijainti-ja-palvelut">
              <span>Sijainti</span>
            </Link>
            <Link activeClassName="activeLink" to="/talo">
              <span>Talo</span>
            </Link>
            <Link activeClassName="activeLink" to="/tyyli-ja-sisustus">
              <span>Tyyli & Sisustus</span>
            </Link>
            <Link activeClassName="activeLink" to="/blogi">
              <span>Blogi</span>
            </Link>
          </Flex>
        </Flex>

        <Flex
          className="mainmenu"
          as="nav"
          width={'260px'}
          bg={'blueDark'}
          color={'beigeText'}
          justifyContent="center"
          alignItems="center"
          display={['none', 'none', 'flex', 'flex']}
        >
          <Link to="/vuokralainen">Vuokralainen</Link>
        </Flex>

        <Flex
          className=""
          as="nav"
          width={1}
          color={'beigeText'}
          justifyContent="flex-end"
          alignItems="center"
          display={['flex', 'flex', 'none', 'none']}
        >
          <Box p={1}>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
