import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Box, Button, Flex } from 'rebass';

export const LinkBtnWhite = styled(props => <Link {...props} />)`
  width: 260px;
  font-family: 'utopia-std', serif;
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #001826;
  background: #fff;
  border-radius: 0px;
  height: 66px;
  line-height: 66px;
  padding: 0;
  z-index: 1;
  &:hover {
    color: #001826;
  }
`;

export const LinkBtnRed = styled(props => <LinkBtnWhite {...props} />)`
  background: #893d3b;
  color: white;
  &:hover {
    color: white;
    opacity: 0.9;
  }
`;

export const LinkBtnGold = styled(props => <LinkBtnWhite {...props} />)`
  background: #b09958;
  color: white;
  transition: all 0.2;
  &:hover {
    color: white;
    background: #b39e64;
    transition: all 0.2;
  }
`;

export const LinkBtnTransparent = styled(props => <LinkBtnWhite {...props} />)`
  background: none;
  color: #001826;
  border: 2px solid #001826;
  &:hover {
    color: #001826;
  }
`;

export const Btn = props => (
  <Button
    onSubmit={e => console.log('asdf')}
    {...props}
    sx={{
      zIndex: '1',
      lineHeight: '66px',
      cursor: 'pointer',
    }}
  />
);

export const BlockFront = props => (
  <Box
    {...props}
    width={[1, 10 / 12, 8 / 12]}
    maxWidth="720px"
    py={4}
    fontSize={5}
    sx={{
      fontFamily: '"utopia-std", serif',
      '& > h2': {
        color: '#fff',
        textTransform: 'uppercase',
        fontWeight: '500',
        marginBottom: '3rem',
      },
    }}
  />
);

export const Page = props => <Box {...props} width="100%" />;

export const Main = props => <Box as="main" overflow="hidden" {...props} />;

export const Section = props => (
  <Box
    as="section"
    {...props}
    sx={{
      position: 'relative',
    }}
  />
);

export const Container = props => (
  <Flex {...props} mx={'auto'} py={4} p={1} maxWidth={'1200px'} />
);

export const Article = props => (
  <Box
    px={'20px'}
    py={6}
    {...props}
    sx={{
      '& h2, h3': {
        letterSpacing: '0',
        fontFamily: 'heading',
      },
      '& h2': {
        fontSize: '32px',
        paddingTop: '26px',
      },
      '& h3': {
        fontSize: '19px',
        textTransform: 'uppercase',
        paddingTop: '22px',
      },
      '& p, table': {
        fontSize: '19px',
        lineHeight: '32px',
        paddingTop: '14px',
      },
      '& blockquote p': {
        fontSize: '32px',
        padding: '32px',
        paddingBottom: '24px',
      },
      '& li': {
        fontSize: '19px',
        lineHeight: '32px',
      },
      '& td, th': {
        padding: '5px',
      },
      '& a': {
        color: 'gold',
        fontWeight: '600',
      },
      '& a:hover': {
        opacity: '.7',
      },
    }}
  />
);
