import React from 'react';
import { bool, func } from 'prop-types';
import { StyledBurger } from './Burger.styled';

// Freeze background when menu open: https://codesandbox.io/s/5kkwqvvopk?codemirror=1&fontsize=14

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};
Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};
export default Burger;
